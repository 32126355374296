export function check_unsupported_css() {
    // Get all stylesheets used by the website
    const styleSheets = [...document.styleSheets];
    let unsupportedStyles = [];

    styleSheets.forEach(styleSheet => {
        try {
            const rules = styleSheet.cssRules || styleSheet.rules; // Get all CSS rules

            [...rules].forEach(rule => {
                if (rule.style) {
                    const styles = rule.style;
                    for (let i = 0; i < styles.length; i++) {
                        const property = styles[i];
                        const value = styles.getPropertyValue(property);

                        // Check if the browser supports the property:value pair
                        if (value && !CSS.supports(property, value)) {
                            unsupportedStyles.push({
                                property,
                                value,
                                selector: rule.selectorText
                            });
                        }
                    }
                }
            });
        } catch (e) {
            // Some stylesheets may not be accessible due to cross-origin policies
            console.warn(`Could not access stylesheet: ${styleSheet.href}`);
        }
    });

    // Output the unsupported styles
    if (unsupportedStyles.length > 0) {
        console.log('Unsupported CSS properties and values:');
        unsupportedStyles.forEach(style => {
            console.log(`Selector: ${style.selector}, Property: ${style.property}, Value: ${style.value}`);
        });
    } else {
        console.log('All CSS features are supported by the current browser.');
    }
}

export function check_unsupported_inline_css() {
    const unsupported = []
    document.querySelectorAll('*').forEach(element => {
        const styles = element.style;
        for (let i = 0; i < styles.length; i++) {
            const property = styles[i];
            const value = styles.getPropertyValue(property);
            if (!CSS.supports(property, value)) {
                unsupported.push({property, value, element})

            }
        }
    });

    if (unsupported.length === 0) {
        console.log(`all Inline style are supported`);
    } else {
        console.log(`list of unsupported inline styles`);
        unsupported.forEach(console.log)
    }
}
