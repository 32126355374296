import {ref} from "nano/reactive.js";

// algorithm related
export const max_translate_words = 5;
export const max_translate_characters = 140;
// in 7(word_review_batch_size)
// random pick 5(per_page_words)
// repeat 5(number_matches) times
export const word_review_batch_size = 7;
export const number_matches = 5;
export const per_page_words = 5; // dev ? 2 : 5;
export const num_correction_promote_stage = 2;
export const per_game_words = 5;
export const game_words_interval = 10;
export const batch_per_level = 3;
export const MAX_WORDS = 5;
export const MAX_BASKET_SIZE = 10;
export const MAX_ARTICLE = 2;
export const word_match_batch_size = word_review_batch_size;
export const max_game_time = ref(3 * 60 * 1000); // testing 10 seconds :-)