export function AsyncImage(src) {
    return new Promise((resolve, reject) => {
        const obj = new Image();
        obj.onload = () => {
            resolve(obj)
        };
        obj.onerror = (error) => {
            reject(error)
        };
        obj.src = src;
    })
}

// this does NOT work on iphone 12, ios17.6
export function AsyncAudioDecoded(audioContext, url) {
    let source
    let audioBuffer
    let gainNode
    let startTime = 0; // Time when the audio started
    let pausedAt = 0; // Time where the audio was paused

    async function load() {
        try {
            // Fetch the audio file as an ArrayBuffer (binary data)
            const response = await fetch(url);
            const arrayBuffer = await response.arrayBuffer();

            // Decode the audio data into an AudioBuffer
            audioBuffer = await audioContext.decodeAudioData(arrayBuffer);
            source = audioContext.createBufferSource();
            source.buffer = audioBuffer;  // Set the buffer to the decoded audio

            // Enable looping
            source.loop = true;  // Loop the audio buffer indefinitely

            // gainNode = audioContext.createGain();
            // source.connect(gainNode);
            // gainNode.connect(audioContext.destination);
            // gainNode.gain.value = 0.5;  // Adjust volume to 50%
            source.connect(audioContext.destination)
            // startTime = audioContext.currentTime - pausedAt;

            console.log(`audio loaded ${url}`)
        } catch (error) {
            console.error('Error loading or decoding the audio file:', error);
        }
    }
    load().then(console.log)

    function play() {
        if (source) {
            source.start(0)
        } else {
            console.error('source not loaded')
        }
    }

    return {
        play,
        audioBuffer,
        resume() {
            source.start(0, pausedAt)
        },
        pause() {
            pausedAt = audioContext.currentTime
            source.stop()
        },
        set volume(v) {
            if (gainNode) {
                gainNode.gain.volume = v
            }
        },
        get volume() {
            return gainNode?.gain?.volume || 1
        }
    }
}


export function AsyncAudio(url) {
    const audio = new Audio(url);
    audio.load();

    function play() {
        audio.play().then(resp => {
            // played
        }).catch(error => {
            console.error(error, url)
        })
    }

    return {
        play,
        pause() {
            audio.pause()
        },
        set volume(v) {
            audio.volume = v
        },
        get volume() {
            return audio.volume
        }
    }
}

export async function async_wrap(fn) {
    return await fn()
}
