export default function (props, children) {
    const {
        size="1em",
        fill="none",
        stroke="currentColor",
        strokeWidth=2,
        strokeLinecap="round",
        strokeLinejoin="round",
        backgroundColor="currentColor"
    } = props;

    return (
        <svg xmlns="http://www.w3.org/2000/svg"
             width={size}
             height={size}
             viewBox="0 0 24 24"
             fill={fill}
             stroke={stroke}
             stroke-width={strokeWidth}
             stroke-linecap={strokeLinecap}
             stroke-linejoin={strokeLinejoin}
             >
            <path d="M1 18 L1 20 L3 20" stroke={backgroundColor} stroke-width="2"/>
            <path d="M18 3 L22 3 L22 7" stroke={backgroundColor} stroke-width="2"/>

        <text x="0" y="11" font-family="sans-serif" font-size="13" fill="currentColor" style="font-weight: 100">文</text>
        <text x="13" y="22" font-family="sans-serif" font-size="12" fill="currentColor" style="font-weight: 100">A</text>

    </svg>)
}