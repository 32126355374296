export function dummy() {

}

// rocky yang => RY
export function to_initialism(name = "") {
    return name.split(" ").slice(0, 2).map(n => n.slice(0, 1)).join("").toUpperCase();
}

let index = 0;

export function gen_date_id(date = new Date()) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");
    const i = String(index).padStart(3, "0");
    index = (index + 1) % 1000;
    return `${year}${month}${day}${hours}${minutes}${seconds}${i}`;
};

export function map_some(array_or_object, fn) {
    if (Array.isArray(array_or_object)) {
        for (let i = 0, n = array_or_object.length; i < n; i += 1) {
            const result = fn(array_or_object[i], i);
            if (result) {
                return result;
            }
        }
    } else {
        const keys = Object.keys(array_or_object);
        for (let i = 0, n = keys.length; i < n; i += 1) {
            const key = keys[i];

            const result = fn(array_or_object[key], key);
            if (result) {
                return result;
            }
        }
    }

    return undefined;
};

export async function async_map(array_or_object, fn) {
    const result = [];
    for (let i = 0, n = array_or_object.length; i < n; i += 1) {
        result.push(await fn(array_or_object[i]));
    }
    return result;
};

export async function async_for_each(array_or_object, fn) {
    if (Array.isArray(array_or_object)) {
        for (let i = 0, n = array_or_object.length; i < n; i += 1) {
            await fn(array_or_object[i], i);
        }
    } else {
        const keys = Object.keys(array_or_object);
        for (let i = 0, n = keys.length; i < n; i += 1) {
            const key = keys[i];

            await fn(array_or_object[key], key);
        }
    }
};

// from an array get value with fn and become a map
export async function array_to_map(ar, fn) {
    const result = [];
    for (let i = 0, n = ar.length; i < n; i += 1) {
        const key = ar[i];
        result.push([key, await fn(key)]);
    }
    return Object.fromEntries(result);
};

const multi_line_comments = /\/\*([\s\S]*?)\*\//g;
const single_line_comments = /[\s\S]*\/\/.*/g;

export const remove_multi_line_comments = (content) => content.replaceAll(multi_line_comments, "");
export const remove_single_line_comments = content => content.split("\n").filter(line => !line.match(single_line_comments)).join("\n");

// partition array/object based on predicate return
// [all_qualifies, all_not_qualifies]
export function partition(ao, predicate) {

    if (Array.isArray(ao)) {
        const result_a = [];
        const result_b = [];
        ao.forEach((item, i) => {
            if (predicate(item, i)) {
                result_a.push(item);
            } else {
                result_b.push(item);
            }

        });
        return [result_a, result_b];
    } else {
        const result_a = {};
        const result_b = {};
        // ao might not be an object, for example DataTransferItem
        Object.entries(ao).forEach(([key, value]) => {
            if (predicate(value, key)) {
                result_a[key] = value;
            } else {
                result_b[key] = value;
            }
        });

        return [result_a, result_b];
    }
}


export function pick(ao, keys) {
    if (Array.isArray(ao)) {
        return ao.filter((_item, i) => keys.includes(i));
    } else {
        // ao might not be an object, for example DataTransferItem
        return Object.fromEntries(keys.map(key => [key, ao[key]]));
    }
}

export function picker(keys) {
    return function (ao) {
        if (Array.isArray(ao)) {
            return ao.filter((_item, i) => keys.includes(i));
        } else {
            // ao might not be an object, for example DataTransferItem
            return Object.fromEntries(keys.map(key => [key, ao[key]]));
        }
    }
}
export function clone_object(obj) {
    return JSON.parse(JSON.stringify(obj));
}

export function shuffler(random = Math.random) {
    return function shuffle(array, i0 = 0, i1 = array.length) {
        let m = i1 - (i0 = +i0);
        while (m) {
            const i = random() * m-- | 0, t = array[m + i0];
            array[m + i0] = array[i + i0];
            array[i + i0] = t;
        }
        return array;
    };
}

export function random_item(array) {
    const i = ~~(Math.random() * array.length)
    return array[i]
}

export function clamp(value, min, max) {
    return Math.max(min, Math.min(value, max));
}