import {ref_render, Render} from "nano/nano.js";
import {
    $current_dom, $left_dom, $next_dom, $prev_dom, $right_dom, get_flow,
    meta, next_page_generators, prev_page_generators, word_index,
    basket, batch,
} from "./store.js";
import {position} from "./store.js";
import {word_review_batch_size} from "./store_algorithm.js";

console.log({type: "tracer"})
export default function Algorithm(props={}, children=[]) {
    const {swipe}= props

    const me = {
        ...props,
        init,
        sequence: 0,
        update_page,
        reupdate_if_needed,
        init_update,
        peak_update_next,
        Mode: undefined,
        current_mode: undefined,
        next_mode: undefined,
        get_Page,
        mode_index: 0,
        onswipedown,
        onswipeup,
        onswipeleft,
        onswiperight,
    }

    swipe.set_handler(
        onswipedown,
        onswipeup,
        onswipeleft,
        onswiperight,)
    // const Modes = {Welcome, Word, Wordnet, Article, Story, Vocabulary, Recorder, Match, Editor, Game}
    // const Pages = {Heritage, Options, Photo, Translator, WordValidator}

    let Pages = undefined
    async function init(fn) {
        // clear all the values
        word_index.value = 0
        batch.value.length = 0
        prev_page_generators.length = 0
        next_page_generators.length = 0
        $left_dom.value = undefined
        $right_dom.value = undefined
        $prev_dom.value = undefined
        $current_dom.value = undefined
        $next_dom.value = undefined

        console.log({prev_page_generators, next_page_generators})
        if (Pages) {
            console.log(`already imported`)
        } else if (fn) {
            Pages = fn()
        } else {
            Pages = await import ('./Pages.jsx')
        }
    }
    function get_Page(name) {
        return Pages[name]
    }
    function get_next_mode() {

        const page = meta.value.pages[me.mode_index]
        me.mode_index = (me.mode_index + 1) % meta.value.pages.length
        const Mode = Pages[page.name]
        if (Mode) {
            return Mode(page, me)
        } else {
            console.error(`${page.name} not defined yet`)
        }
    }

    function push_prev(new_current) {
        if (prev_page_generators.length > word_review_batch_size) {
            prev_page_generators.shift()
        }
        prev_page_generators.push(new_current)
    }

    async function init_update() {
        me.mode = get_next_mode()
        const new_current = await me.mode.get_generator()
        await new_current.generator($current_dom)
        push_prev(new_current)

        const pre_hook= $current_dom.value?.pre_hook
        if (pre_hook) {
            pre_hook()
        }

        // it's the first $next_dom
        await peak_update_next()
    }

    async function peak_update_next(revert) {
        if (me.next_mode) {
            if (revert && me.next_mode.revertable) {
                me.next_mode = me.mode
            }
        }
        else {
            me.next_mode = me.mode
        }

        if (me.next_mode && me.next_mode.goto_next_mode) {
            if (typeof me.next_mode.goto_next_mode === "string") {
                // named next mode
                const name = me.next_mode.goto_next_mode
                const Mode = Pages[name]
                if (Mode) {
                    me.next_mode = Mode({name}, me)
                } else {
                    console.error(`${name} not defined yet`)
                }
            } else {
                // sequential next mode
                me.next_mode = get_next_mode()
            }
        }
        const new_next = await me.next_mode.get_generator()
        await new_next.generator($next_dom)

        push_prev(new_next)
    }

    async function reupdate_if_needed() {
        if ($current_dom.value?.reupdate) {

            const new_current = await me.mode.get_generator()
            await new_current.generator($current_dom)
            push_prev(new_current)

            me.mode.goto_next_mode = false

            await peak_update_next(true)
        } else if ($next_dom.value?.reupdate) {
            await peak_update_next(true)
        }
    }

    async function update_page($dom, name, contexts = {}) {
        const Page = Pages[name]
        $dom.value = Render(() => Page(contexts.props || {}))
    }


    // current <= prev
    // next <= current
    // next <= undefined
    async function onswipedown(event, reason) {
        console.log(`onswipedown`, get_flow())
        // console.log(event)
        // only middle page support swipe down
        if (position.value === 'middle') {
            // only need to handle when there still is $prev page
            // otherwise do nothing
            if ($prev_dom.value) {
                const post_hook = $current_dom.value.post_hook
                const pre_hook = $prev_dom.value.pre_hook

                $next_dom.value = $current_dom.value
                $current_dom.value = $prev_dom.value // now $prev_dom assigned to $current_dom, $prev_dom the element will be empty
                $prev_dom.value = undefined // $prev_dom is cleared now, it will be regenerated if there is more generators left
                // after the current_dom, swipe down, invoke the post_hook first
                if (post_hook) {
                    post_hook()
                }

                // then if the prev_dom will be shown, invoke the prev_hook
                if (pre_hook) {
                    pre_hook()
                }

                // it was previous cached generator
                // next -1, current -2,  prev: -3, before prev
                const prev2_cached = prev_page_generators.at(-4)
                const prev_cached = prev_page_generators.at(-3)

                if (prev2_cached) {
                    // still have prev_cached generator
                    await prev2_cached.generator($prev_dom)
                    const last_cached = prev_page_generators.pop()

                    next_page_generators.push(last_cached)
                } else if (prev_cached) {
                    // does not need to be regenerated
                    const last_cached = prev_page_generators.pop()
                    next_page_generators.push(last_cached)
                } else {
                    // all cached generator is already popped up
                    // it's in the beginning of all words
                    $prev_dom.value = undefined
                }

            }
        }
    }


    async function onswipeup(event, reason) {
        console.log(`onswipeup`, get_flow())
        // only middle page support swipe down
        if (position.value === 'middle') {
            // still has $next page
            // otherwise do nothing
            if ($next_dom.value) {
                // only when the history need to be saved, need to
                // update the $prev_dom and save $prev_dom to the history queue
                // otherwise, $prev_dom will not be changed
                const is_prev_empty = $prev_dom.value === undefined

                const post_hook = $current_dom.value?.post_hook
                const pre_hook = $next_dom.value.pre_hook

                // if the current_dom has just been removed, don't update $prev_dom
                if ($current_dom.value) {
                    $prev_dom.value = $current_dom.value
                }
                $current_dom.value = $next_dom.value
                $next_dom.value = undefined

                if (post_hook) {
                    post_hook()
                }
                if (pre_hook) {
                    pre_hook()
                }


                if (is_prev_empty && prev_page_generators.length > 2) {
                    // in the case of $prev_dom is empty
                    // the cached generator is the last one of prev_page_generators
                    const cached = prev_page_generators.at(-1)
                    await cached.generator($next_dom)
                } else {
                    const next_cached = next_page_generators.pop()
                    if (next_cached) {
                        // it was previous cached generator
                        await next_cached.generator($next_dom)
                        prev_page_generators.push(next_cached)
                    } else {
                        await peak_update_next()
                    }
                }
            }
        }
    }

    // swipe left, goes to right :-)
    async function onswipeleft(event, reason) {
        console.log('onswipeleft')
        // console.log(event)
        if (position.value === 'middle') {
            position.value = 'right'
            // swipe.directions = {left: false, right: true, up: false, down: false}
        } else if (position.value === 'left') {
            position.value = 'middle'
            // swipe.directions = {left: true, right: true, up: true, down: true}
        } else {
            // if it's already right, nothing need to do
        }
    }

    // swipe right, goes to left
    async function onswiperight(event) {
        console.log('onswiperight')
        if (position.value === 'middle') {
            position.value = 'left'
            // on the left position, there is only one page
            // so only swipe right to change position is needed
            // swipe.directions = {left: true, right: false, up: false, down: false}
        } else if (position.value === 'right') {
            position.value = 'middle'
            // swipe.directions = {left: false, right: true, up: true, down: true}
        }
        else {
            // if it's already right, nothing need to do
        }
    }

    return me
}