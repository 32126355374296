import {computed, ref} from "nano/reactive.js";


import api from "./api.js";
import {number_matches, word_review_batch_size} from "./store_algorithm.js";

console.log({type: 'tracer'})

export const app = ref('translingo')

// switches
export const enable_photo = ref(false)
export const enable_report = ref(true)

// UI configs
export const theme = ref('light')
export const change_to_disabled_delay = 400;
export const goto_next_page_delay = change_to_disabled_delay + 300;
// using Iphone 12 Pro Max dimension
export const initial_height = ref(typeof window === "undefined" ? 879 : Math.max(window.innerHeight, window.innerWidth ))
export const initial_width = ref(typeof window === "undefined" ? 428 : Math.min(window.innerHeight, window.innerWidth ))



export let algorithm = ref({})
export const $swipe = ref({})
export const deferred_install = ref();
export const installed_type = ref("browser");
export const offline = ref()
export const $token = ref()
export const $app_language = ref('english')
export const $active_learning_language = ref('swedish')
export const $languages = ref(['swedish', 'english'])
export const $native_languages = ref(['english'])
export const $passive_learning_languages = ref(['english'])
export const position = ref("middle")
export let audioContext = undefined
export function get_audioContext() {
    if (audioContext) {
        return audioContext
    } else {
        audioContext = new (window.AudioContext || window.webkitAudioContext)();
    }
}

export const app_theme = ref('dark')
export const page_theme = ref(theme)

export const current_progress  = ref(1, undefined, 'current_progress')  // for UI progress bar
export const next_progress  = ref(1, undefined, 'next_progress')        // for get progress for save it
export const progress_total = ref(word_review_batch_size + number_matches + 1)
export const progress_percent = computed(() => current_progress.value / progress_total.value * 100)
export const onvisibilitychanges = new Set()


export const header = ref(false)
export const foreground = ref(false)


export const basket = ref([])
export function update_basket(array) {
    const value = basket.value
    array.forEach((v, i) => {
        value[i] = v
    })
}

export const batch = ref([])
export const word_index = ref(0)
export const $current_dom = ref(undefined, undefined, '$current_dom')
export const $right_dom = ref(undefined, undefined, '$right_dom')
export const $left_dom = ref(undefined, undefined, '$left_dom')
export const $next_dom = ref(undefined, undefined, '$next_dom')
export const $prev_dom = ref(undefined, undefined, '$prev_dom')
export const $prev = ref()
export const $next = ref()
export const $current = ref()
export const $right = ref()
export const $left = ref()

export const prev_page_generators = []
export const next_page_generators = []

export const meta = ref({});
export function update_meta(value) {
    meta.value = value
    basket.value = value.basket
    $active_learning_language.value = value.active_learning_language
    $app_language.value = value.app_language
    $languages.value = value.languages
    $native_languages.value = value.native_languages
    $passive_learning_languages.value = value.passive_learning_languages
}

export async function get_translation(text, language) {
    text = text.trim().toLowerCase()
    const response = await api.get('/api/translation', {text, language})
    if (response?.ok) {
        const {data} = response
        response.language = data.language || data.detected
        response.corrected = data[response.language]
        response.typo = response.corrected !== text

        response.inserted = basket.upsert(data, (d) => {
            // basket has hole if some words has been removed
            return d && d.id === data.id
        })
    }
    return response
}

function safe_dataset(dom, name) {
    return (typeof dom === "object" && typeof dom.dataset === "object") ? dom.dataset[name] : dom
}

export function get_flow() {
    return {
        prevs: prev_page_generators.map(({name, generator}) => name),
        prev: safe_dataset($prev_dom.value, 'name') || '',
        current: safe_dataset($current_dom.value, 'name') || '',
        next: safe_dataset($next_dom.value, 'name') || '',
        left:safe_dataset($left_dom.value, 'name') || '',
        right:safe_dataset($right_dom.value, 'name') || '',
        nexts:  next_page_generators.map(({name, generator}) => name),
    }
}


