// https://youtu.be/s06Z_e8ac0Y?si=bGNaxrCmJUxANHtk
// https://www.youtube.com/watch?v=8q_05PUYv1o
//      https://codepen.io/cassie-codes/full/xxperqo
export function flip_effect(options = {transformOrigin: "top left", duration : 1000, easing : "ease-in-out", fill: "both"}) {
    return function effect(first, last,) {
        if (first && last) {
            const dx = first.x - last.x;
            const dy = first.y - last.y;
            const scale_x = first.width / last.width;
            const scale_y = first.height / last.height;
            console.log({dx, dy, scale_x, scale_y});
            const transform = `translate(${dx}px, ${dy}px) scale(${scale_x}, ${scale_y})`;
            console.log("transform", transform);
            const keyframes = [{
                transformOrigin: options.transformOrigin,
                transform
            }, {
                transformOrigin: options.transformOrigin,
                transform: "none",
            }];
            return [keyframes, options];
        } else {
            return [undefined, undefined];
        }
    }
}

export function grow(options={duration: 100, easing: "ease-in"}) {
    return function grow_effect(first, last) {
        const keyframes = [{
            height: "1px"

        }, {
            height: `${last.height}px`
        }];
        return [keyframes, options];
    }
}

export function shrink(options={duration: 100, easing: "ease-in"}) {
    return function shrink_effect(first, last) {
        const keyframes = [{
            height: `${first.height}px`
        }, {
            height: "1px"
        }];
        return [keyframes, options];
    }
}

export function fade_in(options={duration: 300, easing: "ease-out"}) {
    return function fade_in_effect(first, last) {
        const keyframes = [{
            opacity: 0,
        }, {
            opacity: 1,
        }];
        return [keyframes, options];
    }
}
// fly in from top      : translate(0,      -100px)
// fly in from below    : translate(0,      100px)
// fly in from right    : translate(-100px, 0)
// fly in from left     : translate(100px,  0)
// fly in from top left : translate(-100px, -100px)
export function fly_in_from_top(options={transform: "translate(0, -100px)", duration : 300, staggering: true, easing: "ease-out"}) {
    return fly_in(options)
}

export function fly_in(options) {
    return function effect(first, last) {
        const keyframes = [
            {
                opacity: 0,
                transform: options.transform,
                height: 0,
                offset: 0,
            },
            {
                opacity: 1,
                height: `${last.height}px`,
                transform: "translate(0, 0)",
                offset: 1,
            }
        ];
        return [keyframes, options];
    }
}

// fly up out        : translate(0,      -100px)
// fly down out      : translate(0,      100px)
// fly right out     : translate(-100px, 0)
// fly left out      : translate(100px,  0)
// fly top left out  : translate(-100px, -100px)
export function fly_out_up(options={transform: "translate(0, -100px)", duration : 300, staggering: true, easing: "ease-in"}) {
    return fly_out(options)
}
export function fly_out(options) {
    return function fly_out_effect(first, last) {
        const keyframes = [
            {
                opacity: 1,
                height: `${first.height}px`,
                transform: "translate(0, 0)",
                offset: 0,
            },
            // {
            //     opacity: 1,
            //     height: 0,
            //     transform: "translate(0, 0)",
            //     offset: 0.4
            // },
            {
                opacity: 0,
                height: 0,
                transform: options.transform,
                offset: 1,
            }
        ];
        return [keyframes, options];
    }
}