export function set_cookie(name, value, days) {
    const expirationDate = new Date();
    expirationDate.setDate(expirationDate.getDate() + days); // Calculate expiration date

    const expires = "expires=" + expirationDate.toUTCString();
    document.cookie = name + "=" + value + ";" + expires + ";path=/";
}

export function get_cookies() {
    const cookies = document.cookie.split(";").map(function trim(s) {
        return s.trim();
    });
    const kv = cookies.map(cookie => cookie.split("="));
    return Object.fromEntries(kv);
}

export function get_cookie(name) {
    const cookies = get_cookies();
    return cookies[name];
}

const symbol_regex = /[,;&…\.\(\)（、…）]/g;
const lemma_regex = /[a-zA-Z]+\./g;

export function remove_symbols(s) {
    return s.replaceAll(/^\.+|\.+$/g, "").replaceAll(lemma_regex, " ").replaceAll(symbol_regex, " ");
}

export function get_yyyymmdd(now = new Date()) {
    const y = now.getFullYear();
    const m = now.getMonth() + 1;
    const d = now.getDate();

    return y * 10000 + m * 100 + d;
}

export function get_yyyymmdd_hhmmss(now = new Date()) {
    const y = now.getFullYear();
    const m = now.getMonth() + 1;
    const d = now.getDate();
    const h = now.getHours();
    const M = now.getMinutes();
    const s = now.getSeconds();

    return y * 10000_000000 + m * 100_000000 + d * 1_000000 + h * 10000 + M * 100 + s;
}

