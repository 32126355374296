import {add_event_listener} from "nano/dom.js";
import {Render} from "nano/nano.js";
import "./index.css";
import "./components.css"

import App from "./App.jsx";
import {get_cookie, set_cookie} from "./util.js";
import {app_theme, installed_type, offline, onvisibilitychanges} from "./store.js";
import {check_unsupported_inline_css, check_unsupported_css} from 'nano/lib/unsupported.js'


if (document.readyState !== "loading") {
    on_ready().then(() => {
        console.log("ready");
    })
} else {
    add_event_listener(document, "DOMContentLoaded", on_ready);
}

async function on_ready() {
    requestAnimationFrame(async () => {
        const Pages = await import('./Pages.jsx').default
        // console.log(Pages)
    })
    window.check_unsupported_inline_css = check_unsupported_inline_css
    window.check_unsupported_css = check_unsupported_css
    setTimeout(async () => {

        check_unsupported_inline_css()
        check_unsupported_css()
    }, 3000)


    install_sw()

    normalize_center()
    const el = Render(App)
    console.log(el);
    const app = document.getElementById('app')
    app.replaceWith(el)

    await try_lock_orientation()
    await for_extension()

    window.addEventListener("online", () => {
        offline.value = false;
    });

    window.addEventListener("offline", () => {
        offline.value = true;
    });

    document.onvisibilitychange = function onvisibilitychange(event) {
        onvisibilitychanges.forEach(callback => {
            return callback(event)
        })
    }
    onvisibilitychanges.add(detect_theme_change)
    detect_theme_change()
    await import('./exposer.js')
}

function detect_theme_change(event) {
    const is_light = window.matchMedia("(prefers-color-scheme: light)").matches;
    app_theme.value = is_light ? 'light' : 'dark'
    window.matchMedia("(prefers-color-scheme: light)").onchange = function (event) {
        console.log(event)
        app_theme.value = event.matches ? 'light' : 'dark';
        document.body.dataset['theme'] = app_theme.value
    };
    document.body.dataset['theme'] = app_theme.value
}

function normalize_center() {
    const size = 9
    const style = {
        position: 'fixed',
        width: `${size}px`,
        height: `${size}px`,
        left: `${-size - 1}px`,
        top: `${-size - 1}px`,
    }
    const $d = Render(() => {
        return (<div id="normalize_center" style={style}></div>)
    })
    document.body.appendChild($d)
    const rect = $d.getBoundingClientRect()
    console.log(rect)
}
async function try_lock_orientation() {
    if ("ScreenOrientation" in window && window.ScreenOrientation.lock) {
        try {
            await window.ScreenOrientation.lock("portrait");
        } catch (error) {
            console.log("lock portrait", error);
        }
    }
}
async function for_extension() {
    if (/chrome/i.test(navigator.userAgent) && import.meta.env.MODE === "dev") {
        const content_css = await import("../../../translingo/content.css");
        const content = (await import("../../../translingo/content.js")).default;
        console.log(content_css, content);
    }
    requestAnimationFrame(() => {
        const token = localStorage.getItem("token");
        if (token) {
            window.postMessage({app: "translingo", type: "token", token});
        }
    });

    if ("ScreenOrientation" in window && window.ScreenOrientation.lock) {
        try {
            await window.ScreenOrientation.lock("portrait");
        } catch (error) {
            console.log("lock portrait", error);
        }
    }
}
function install_sw() {

    if ("serviceWorker" in navigator) {
        navigator.serviceWorker.register("sw.js").then(registration => {
            console.log(registration);
        }).catch(console.error);

        function on_message_from_sw(event) {
            console.log(event);
        }
        navigator.serviceWorker.addEventListener("message", on_message_from_sw);
        window.addEventListener("appinstalled", function onappinstalled(event) {
            console.log("appinstalled", event);
        });

        async function install(event) {
            if (window.deferred_install_event) {
                deferred_install_event.prompt();
                const choice = await deferred_install_event.userChoice;
                console.log(choice);

                const app = document.getElementById("app");
                window.removeEventListener("beforeinstallprompt", onbeforeinstallprompt);
                app.removeEventListener("click", install);
                app.removeEventListener("tap", install);
                if (choice.outcome === "accepted") {
                    // show_install_button.value = false;
                    console.log("installed");
                } else {
                    console.log("canceled");
                    set_cookie("installation_canceled", "true", 3);
                }
            }
        }

        function onbeforeinstallprompt(deferred_install_event) {
            console.log("beforeinstallprompt", deferred_install_event);
            deferred_install_event.preventDefault();
            window.deferred_install_event = deferred_install_event;
            const install_delay_trigger = 10000;
            setTimeout(() => {
                const app = document.getElementById("app");
                app.addEventListener("click", install);
                app.addEventListener("tap", install);
            }, install_delay_trigger);
        }

        const installation_canceled = get_cookie("installation_canceled");
        if (installation_canceled === "true") {
            console.log("will be asked again to install in 3 days");
        } else {
            window.addEventListener("beforeinstallprompt", onbeforeinstallprompt);
        }

        if ("standalone" in navigator && navigator.standalone) {
            console.log("installed on iOS");
            installed_type.value = "iOS";
        } else if (matchMedia("(display-mode: standalone)").matches) {
            console.log("installed on Android or desktop");
            installed_type.value = "desktop";
        } else {
            console.log("rendered from browser");
            installed_type.value = "browser";
        }

        requestAnimationFrame(() => {
            const controller = navigator?.serviceWorker?.controller
            if (controller) {
                controller.postMessage({type: "check_online"});
            }
        });

    } else {
        console.log("serviceWorker not supported");
    }
}