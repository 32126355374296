import {flip_effect, fly_in_from_top, fly_out, fly_out_up, shrink} from "./effects.js";
import {clone_object, partition} from "./lib/_.js";

console.log("dom");

export function swap_node (nodeA, nodeB) {
    const parentA = nodeA.parentNode;
    const siblingA = nodeA.nextSibling === nodeB ? nodeA : nodeA.nextSibling;

    // Move `nodeA` to before the `nodeB`
    nodeB.parentNode.insertBefore(nodeA, nodeB);

    siblingA ? parentA.insertBefore(nodeB, siblingA) : parentA.appendChild(nodeB);
};


export async function animate($el, keyframes, options) {
    const animation = $el.animate(keyframes, options);

    return animation.finished;
}

export async function flip($el, first, last) {
    const [keyframes, options] = flip_effect()(first, last);
    return animate($el, keyframes, options);
}

// TODO staggering feature is not working yet
function multi_animate(promises, $el, effect, {i=0}) {
    const [keyframes, options] = effect($el._first || $el._last, $el._last || $el._first);
    if (keyframes && options) {
        promises.push(animate($el, keyframes, {delay: i * options.duration, ...options}));
    }
}

export async function flips(added, moved, removed, {add_effect, move_effect, remove_effect}) {

    const promises = [];
    if (added && add_effect) {
        const effect = typeof add_effect === 'function' ? add_effect : fly_in_from_top()
        added.forEach(($el, i) => {
            multi_animate(promises, $el, effect, {i});
        });
    }

    if (moved && move_effect) {
        const effect = typeof move_effect === 'function' ? move_effect : flip_effect()
        moved.forEach(($el, i) => {
            multi_animate(promises, $el, effect, {i});
        });
    }

    if (removed && remove_effect) {
        const effect = typeof remove_effect === 'function' ? remove_effect : fly_out_up()
        removed.forEach(($el, i) => {
            multi_animate(promises, $el, effect, {i});
        });
    }

    await Promise.all(promises);
}

function is_element_node($el) {
    return $el.nodeType === Node.ELEMENT_NODE;
    // return $el.nodeType !== Node.TEXT_NODE;
}

function cancel_bubbling(event) {
    event.cancelBubble = true;
    event.stopPropagation && event.stopPropagation();
}


const sanitize_map = {
    "&": "&amp;",
    "<": "&lt;",
    ">": "&gt;",
    "\"": "&quot;",
    "'": "&#x27;",
    "/": "&#x2F;"
};

function remove_script(input) {
    return input.replace(/<\s*script\s*>.*<\/\s*script\s*>/g, "");
}

export function sanitize(input) {
    // Escape HTML special characters
    const sanitized = typeof input === "string" ? remove_script(input) : input;
    if (sanitized !== input) {
        console.log(input, sanitized);
    }
    return sanitized;
}

function js_to_css_case(str) {
    return str.replace(/[A-Z]/g, letter => `-${letter.toLowerCase()}`);
}

// some attributes are in camelCase not like other attributes as dash-case
const camelCases = new Set([
    "contentEditable",
    "markerWidth", "markerHeight", "markerUnits",
    "viewBox",
    "baseFrequency", "numOctaves", "xChannelSelector", "yChannelSelector"
]);

function option_js_to_css_case(key) {
    return camelCases.has(key) ? key : js_to_css_case(key);
}

const key_map = (function () {
    const maps = {
        "className": "class",
        "onClick": "onclick"
    };
    return (key) => {
        return option_js_to_css_case(maps[key] || key);
    };
}());

export function set($el, type, val) {
    switch (type) {
        case "value":
            return $el.value = val;
        case "checked":
            return $el.checked = sanitize(val);
        case "innerHTML":
            return $el.innerHTML = sanitize(val);
        default:
            set_attribute($el, type, val);
    }
}

export function enable_class($el, enabled, clazz) {
    try {
        if (clazz) {
            enabled ? $el.classList.add(clazz) : $el.classList.remove(clazz);
        }
    } catch (error) {
        console.error({$el, name, clazz});
    }
}

export function set_style($el, name, value) {
    const is_variable = name.startsWith("--");
    try {
        if (value === undefined) {
            // nothing need to be done
        } else {
            if (is_variable) {
                $el.style.setProperty(name, value);
            } else {
                $el.style[name] = value;
            }
        }
    } catch (error) {
        console.error({$el, name, value});
    }
}

export function set_attribute($el, name, val) {
    try {
        const key = key_map(name)
        if (key === 'value') {
            $el.value = val
        } else {
            const sanitized = sanitize(val)
            $el.setAttribute(key, sanitized);
        }
    } catch (error) {
        console.error({$el, name, val});
    }
}

export function dynamic_function_name(name, fun, $el, event_name, listener) {
    const new_fun = fun.toString().replace(/function [a-zA-Z_][a-zA-Z0-9_]*/, `function ${name}`);
    const f = new Function("$el", "event_name", "listener", `return ${new_fun}`);
    return f($el, event_name, listener);
}

export function add_event_listener($el, event_name, listener, {capture = false} = {}) {
    $el.event_listeners = $el.event_listeners || [];
    // char charCode which
    // TODO here can patch to make the event handler normalized

    async function normalized_listener(event, {stop = false, prevent = false} = {}) {
        event = event || window.event;
        // Cannot set property target of #<Event> which has only a getter
        // event.target = event.target || event.srcElement;

        if (stop) {
            // According to Douglas Crockford, there are 2 ways of stop propagation
            // while might be now only ONE way is needed
            event.cancelBubble = true;
            event.stopPropagation && event.stopPropagation();
        }
        if (prevent) {
            // According to Douglas Crockford, there are 3 ways of prevent default behavior
            // while might be now only ONE way is needed
            // event.returnValue = false
            event.preventDefault && event.preventDefault();
        }

        // According to Douglas Crockford, there are 3 ways of get char code
        // while might be now only ONE way is needed
        // event.charCode = event.char || event.charCode || event.which;
        try {
            await listener(event);
        } catch (error) {
            console.error({error, $el, event_name, listener});
        }
        if (prevent) {
            return false;
        }
    };


    try {
        // if (import.meta.env.DEV) {
        //     const at = Date.now();
        //     if (event_name === 'touchclick') {
        //         const dynamic_named = dynamic_function_name(`click_${listener.name}`, normalized_listener, $el, event_name, listener);
        //         $el.addEventListener('click', dynamic_named, capture);
        //         $el.event_listeners.push({event_name: 'click', listener, normalized_listener: dynamic_named, at});
        //
        //         const dynamic_named_touchstart = dynamic_function_name(`touchstart_${listener.name}`, normalized_listener, $el, event_name, listener);
        //         $el.addEventListener('touchstart', dynamic_named_touchstart, capture);
        //         $el.event_listeners.push({event_name: 'touchstart', listener, normalized_listener: dynamic_named_touchstart, at});
        //
        //     } else {
        //         const dynamic_named = dynamic_function_name(`${event_name}_${listener.name}`, normalized_listener, $el, event_name, listener);
        //         $el.addEventListener(event_name, dynamic_named, capture);
        //         $el.event_listeners.push({event_name, listener, normalized_listener: dynamic_named, at});
        //     }
        // } else {
        if (event_name === "touchclick") {
            $el.addEventListener("click", listener, capture);
            $el.addEventListener("touchstart", (event) => {
                // event.preventDefault();
                listener(event);
            }, capture);
        } else {
            $el.addEventListener(event_name, listener, capture);
        }

        // }
    } catch (error) {
        console.warn($el, event_name, listener);
    }
}

export function remove_event_listener($el, event_name, listener) {

    // const is_prod = true;
    // if (import.meta.env.DEV) {
    //     const [my_listeners, other_listeners] = partition($el.event_listeners,
    //         e => e.event_name === event_name && e.listener === listener);
    //
    //     $el.event_listeners = other_listeners;
    //
    //     my_listeners.forEach(item => {
    //         $el.removeEventListener(event_name, item.normalized_listener);
    //     });
    // } else {
    $el.removeEventListener(event_name, listener);
    // }
}

export function remove_all_event_listeners($el) {
    ($el.event_listeners || []).forEach(e => {
        $el.removeEventListener(e.event, e.listener);
    });
}


export async function add_element($parent, $el, $prev, effect) {
    try {
        if ($prev) {
            $prev.after($el)
        } else {
            $parent.append($el);
        }
        if (is_element_node($el) && effect) {
            const first = undefined;
            const last = $el.getBoundingClientRect();
            const [keyframes, options] = effect(first, last);
            await animate($el, keyframes, options);
            return true;
        } else {
            return false;
        }
    } catch (error) {
        console.error(error, $parent, $el);
    }
}


export async function remove_element($el, effect = fly_out()) {
    if (is_element_node($el) && effect) {
        const first = $el.getBoundingClientRect();
        const last = clone_object(first) // {...JSON.parse(JSON.stringify(first))};
        console.log({first, last});

        await flip($el, first, last);

        // const [keyframes, options] = effect(first, last, {});
        // await animate($el, keyframes, options);
    }
    remove_all_event_listeners($el);
    $el.remove();
}

export async function move_item(from_array_ref, to_array_ref, d) {
    const $el = d.$el;
    // 1 get first
    const first = d.$el.getBoundingClientRect();
    console.log("first", first);
    // 2 set d.$el to undefined, since we want to recreate the element
    // so that all the event listeners will be attached properly
    d.$el = undefined;
    const cloned = clone_object(d)

    $el.style.opacity = `0.1`;



        // 3 append to_array_ref
        // 4 it will trigger create_element and d.$el will be assigned
        // 5 the $el will be appended to the parent
        to_array_ref.push(cloned);
        const $cloned = cloned.$el

        // 6 get last
        const last = $cloned.getBoundingClientRect();
        console.log("last", last);
        // 7 flip animation
        await flip($cloned, first, last);

        // 8 remove element
        from_array_ref.remove(d);
        // await remove_element($el, shrink);
}

export function adjust_element_size({
                                        max_font_size= 50,
                                        width=300,
                                        tolerance=0.2,
                                        font_width_ratio=0.65,
                                        max_rows=3,
                                        ruby= false,
                                        origin,
                                    }) {
    return function adjust_size($el) {
        const words = (origin || $el.textContent).split(' ')
        const max_word_length = words.reduce((acc, curr) => acc > curr.length ? acc : curr.length, 0)
        const estimate_length = Math.max(max_word_length, $el.textContent.length / max_rows)
        let fontSize = Math.min(width / estimate_length / font_width_ratio, max_font_size)
        let iterations = 5
        function adjust() {
            $el.style['font-size'] = `${fontSize}px`
            const rect = $el.getBoundingClientRect()
            if (rect.width === 0) {
                // console.log(rect)
            } else {
                const shift = (width - rect.width) / estimate_length

                if (Math.abs(shift) > tolerance && ((max_font_size - fontSize) > tolerance) && iterations > 0) {
                    fontSize = Math.min(fontSize + shift / font_width_ratio, max_font_size)
                    iterations -= 1
                    requestAnimationFrame(adjust)
                }
            }
        }
        adjust()
    }
}