
let last_activity_timestamp = Date.now()
export function update_activity(timestamp) {
    last_activity_timestamp = timestamp
}

const reload_interval = 60 * 60 * 1000 // 1 hour
function check_reload() {
    console.log('check_reload')
    const cached = localStorage.getItem('last_activity_time')
    const now = Date.now()
    const actual_timestamp = Date.now() - performance.now() + last_activity_timestamp;

    localStorage.setItem('last_activity_time', actual_timestamp.toString(10))
    if (cached && (+cached - now) > reload_interval) {
        window.location.reload()
    }
}

setInterval(check_reload, reload_interval / 10)
